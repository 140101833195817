import React, { Component } from 'react';
import Actions from '../data/Actions';
import trashicon from "../trash.svg";
import {Card, ListGroup, ListGroupItem, Form, Button, Modal, Row, Col } from 'react-bootstrap';

const styles = {
  section: {
    "padding": "5px 20px 5px 20px"
  },
  btnGap: {
  "margin": "25px 0px 25px 0px"
  },
  remove: {
    "cursor": "pointer"
  }
}

const initFields = {make: "", model: "", year: ""}

class VehicleInfo extends Component {
  constructor(props) {
      super(props);
      this.state = {
        validated: false,
        vehicleModal: false,
        vehArr: [],
        fields: initFields
      }
      this.handleAddVehicle = this.handleAddVehicle.bind(this);
      this.handleToggleModal = this.handleToggleModal.bind(this);
      this.handleDeleteVehicle = this.handleDeleteVehicle.bind(this);
      this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount () {
    let vehArr = this.props.userData? this.props.userData.vehicleInfo? this.props.userData.vehicleInfo : null : null; 
    if (vehArr.length > 0) {
      this.setState({vehArr: vehArr});
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.userData.vehicleInfo.length !== this.props.userData.vehicleInfo.length) {
        this.setState({vehArr: this.props.userData.vehicleInfo})
    }
  }

  handleToggleModal () {
    this.setState({vehicleModal: !this.state.vehicleModal});
    this.setState({fields: initFields});
  }

  handleAddVehicle (e) {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    this.setState({ validated: true });
    if (form.checkValidity()) {
      let payload = this.state.fields;
      this.setState({fields: initFields});
      this.setState({vehicleModal: false});
      this.setState({validated: false});
      Actions.addVehicle(payload);
    }
  }

  handleDeleteVehicle (e) {
      Actions.remVehicle(e.target.id);
  }

  handleChange ( e ) {
    let newFields = Object.assign({}, this.state.fields, {[e.target.id] : e.target.value})
    this.setState({fields: newFields});
  }

  render() {
  
  const vehicleBtnText = this.state.vehArr.length > 0 ? "+ Add another vehicle" : "+ Add at least one vehicle"
  const HeaderCnt = "Total number of vehicles : " + this.state.vehArr.length.toString();

  return (
      <div>
      <h2 className="lighttext">Vehicle Information</h2>
      <Row>
        <Col>
      <Card>
      <Card.Body>
      <Card.Title>{ HeaderCnt } </Card.Title>
          <ListGroup>
            { this.state.vehArr.map(( veh, index ) => {
              return <ListGroupItem key={index} header={"Vehicle " + (index + 1)}>
                  <span className="float-right" key={index} > 
                  <img src={trashicon} alt="delete" id={index} style={styles.remove} onClick={this.handleDeleteVehicle}/>
                  </span>
                <strong>Year:</strong> {veh.year} <strong>Make:</strong> {veh.make} <strong>Model:</strong> {veh.model} </ListGroupItem>
            })}
          </ListGroup>
          <Button onClick={this.handleToggleModal} variant="primary">{vehicleBtnText}</Button>
          </Card.Body>
      </Card>
      </Col>
      </Row>

   
        <Modal id="vehicleModal" title="Add a vehicle" show={this.state.vehicleModal} onHide={this.handleToggleModal}>
        <Form noValidate validated={this.state.validated} onSubmit={e => this.handleAddVehicle(e)}>
        <Modal.Header closeButton>
            <Modal.Title>Add a vehicle.</Modal.Title>
          </Modal.Header>

        <div style={styles.section}>
        <Form.Group controlId="year">
          <Form.Label>Year</Form.Label>
          <Form.Control type="text" onChange={this.handleChange} value={this.state.fields.year} required/>
          <Form.Control.Feedback type="invalid">Please enter the vehicle's model year.</Form.Control.Feedback>
        </Form.Group>
        </div>

        <div style={styles.section} >
        <Form.Group controlId="make"  >
          <Form.Label>Make</Form.Label>
          <Form.Control type="text" onChange={this.handleChange} value={this.state.fields.make} required/>
          <Form.Control.Feedback type="invalid">Please enter the vehicle's make.</Form.Control.Feedback>
        </Form.Group>
        </div>
        
        <div style={styles.section} >
        <Form.Group controlId="model" >
          <Form.Label>Model</Form.Label>
          <Form.Control type="text" onChange={this.handleChange} value={this.state.fields.model} required/>
          <Form.Control.Feedback type="invalid">Please enter the vehicle's model.</Form.Control.Feedback>
        </Form.Group>

        </div>

          
          <Modal.Footer>
          <Button onClick={this.handleToggleModal}>Cancel</Button>
          <Button variant="primary" type="submit" className="float-right">Add vehicle </Button>
          </Modal.Footer>
          </Form>
          </Modal>


          <Row>
            <Col>
            <p style={styles.btnGap}>
            <Button variant="light" onClick={Actions.navBack}> &larr; Go Back</Button>
            <Button variant="primary" className="float-right" disabled={this.state.vehArr.length < 1? true: false} onClick={Actions.evalSave} >Order Summary  </Button>
            </p>
            </Col>
          </Row>

      </div>

      )
    }
}

export default VehicleInfo;