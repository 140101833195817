const Constants = {
    CONFIG_GET: 'CONFIG_GET',
    CONFIG_GOT: 'CONFIG_GOT', 
    PREVIOUS_STEP: 'PREVIOUS_STEP',
    LOG_USER_IN: 'LOG_USER_IN',
    SAVE_USER_INFO: 'SAVE_USER_INFO',
    SELECT_DAY: 'SELECT_DAY',
    SELECT_SLOT: 'SELECT_SLOT',
    SCHEDULE_SESSION: 'SCHEDULE_SESSION',
    SESSION_CONFIRMED: 'SESSION_CONFIRMED',
    CONFIRM_ORDER: 'CONFIRM_ORDER',
    ORDER_CONFIRMED: 'ORDER_CONFIRMED',
    NAV_FWD: 'NAV_FWD',
    NAV_BACK: 'NAV_BACK',
    UPDATE_DATA: 'UPDATE_DATA',
    UPDATE_LOCATION: ' UPDATE_LOCATION',
    ADD_CHILD: 'ADD_CHILD',
    ADD_VEHICLE: 'ADD_VEHICLE',
    REM_CHILD: 'REM_CHILD',
    REM_VEHICLE: 'REM_VEHICLE',
    SOCIAL_LOGIN: 'SOCIAL_LOGIN',
    EVAL_SAVE: 'EVAL_SAVE',
    EVAL_SAVED: 'EVAL_SAVED',
    SET_IDENTITY: 'SET_IDENTITY',
    IDENTITY_SET: 'IDENTITY_SET',
    IDENTITY_FAILED: 'IDENTITY_FAILED',
    CREDENTIALS_SET: 'CREDENTIALS_SET',
    CREDENTIALS_GOT: 'CREDENTIALS_GOT',
    CREDENTIALS_FAILED: 'CREDENTIALS_FAILED',
    GEN_STATUS: 'GEN_STATUS',
    CONFIRM_PAYMENT: 'CONFIRM_PAYMENT',
    AVAILABILITY_GOT: 'AVAILABILITY_GOT',
    SYNCED_DATA_GOT: 'SYNCED_DATA_GOT',
    SYNCED_DATA_PUT: 'SYNCED_DATA_PUT',
    COURT_DATA_SAVE: 'COURT_DATA_SAVE',
    COURT_SELECT: 'COURT_SELECT',
    LOGOUT: 'LOGOUT',
    CLOSEST_CITY_GOT: 'CLOSEST_CITY_GOT',
    LOGGED_OUT: 'LOGGED_OUT',
    CHECK_FOR_CREDS: 'CHECK_FOR_CREDS',
    UPDATE_PARENT: 'UPDATE_PARENT',
    MAKE_NEW_APPOINTMENT: 'MAKE_NEW_APPOINTMENT'
  };
  
  export default Constants;
  
  