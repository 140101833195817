
import React, { Component } from 'react';

import Actions from '../data/Actions';
import Store from '../data/Store';

import { Row, Col, Container, Alert, ProgressBar } from 'react-bootstrap';

import Landing from './Landing';
import Location from './Location';
import CourtAppoint from './Court.appoint';
import ParentInfo from './Parent.info';
import ChildInfo from './Child.info';
import VehicleInfo from './Vehicle.info';
import OrderSummary from './Order.summary';
import Confirmation from './Confirmation';
import SessionSchedule from './Session.schedule';
import SessionConfirm from './Session.confirm';


const styles = {
  paypal: {
    "textAlign": "right"
  },
  alert: {
    "marginTop": "15px"
  },
  prog: {
    "marginTop": "35px"
  },
  logbar: {
    "height": "34px",
    "lineHeight": "30px",
    "color": "#efefef",
    "width": "100%",
    "backgroundColor": "#4286f4",
    "align": "right",
    "verticalAlign": "middle"
  },
  logoutlink: {
    "color": "#efefef"
  },
  logtext: {
    "fontSize": "small",
    "display": "inline-cell",
    "textAlign": "right"
  },
  logout: { 
    "marginLeft": "50px",
    "marginRight": "25px",
    "border": "1px solid white",
    "borderRadius": "5px",
    "backgroundColor": "#f2373d",
    "padding": "5px" 
  }
}

function getState() {
  return Store.getState();
}

const createAlert = ( style, mess ) => <Alert variant={ style }>{ mess }</Alert>;

class LogStat extends Component {
    render() {
      let logEmail = this.props.socialAttr? this.props.socialAttr.email ? this.props.socialAttr.email : null : null;
      let hasWebId = this.props.webIdForCreds? this.props.webIdForCreds : null; 

//      let logEmail = this.props.getIn(['appdata', 'socialAttr', 'email'] );
//      let hasWebId = this.props.getIn(['appdata', 'webIdForCreds']);

    if ( !hasWebId ) {
      return null;
    } else {
      return (
        <div style={ styles.logbar } className="pull-right">
          <p className="pull-right" style={styles.logtext} >Logged in as { logEmail } 
            <span style={styles.logout}><a href="/" style={styles.logoutlink} onClick={ Actions.logOut }>Logout</a></span></p>
        </div>
      )
    }
  }
}

class Header extends Component {
  render() {
    return (
    <div className="App-header">
      <span className="App-header-text"> Pro Car Seat Safety </span>
      </div>
    )
  }
}

class ProgBar extends Component {
  render() {
    let prog = this.props.userData? this.props.userData.progress? this.props.userData.progress : null : null; 
    let hasWebId = this.props.webIdForCreds? this.props.webIdForCreds : null; 

    if ( !hasWebId || prog === 0 ) {
      return null;
    } else {
      let incr =  100 / 9  ;
      let now =  Math.round( incr * prog );

      return (
        <div style={ styles.prog }>
        <h5 className="lighttext">Progress</h5>
        <ProgressBar now={now} label={`${now}%`} />
        </div>
      )
    }
  }
}

class Alerts extends Component {
  render() {
    let alertInstance = null;
    let message = this.props.status? this.props.status.message? this.props.status.message : null : null;  
  
    if (message) {
      alertInstance = createAlert( this.props.status.style, this.props.status.message);
    } 
    
    return (
    <Col xs={12} md={12} style={ styles.alert }>
    { alertInstance }
    </Col>
    )
  }
}

class Main extends Component {
  render() {
    return (
    <Row>
      <Col>
      { this.props.children }

    </Col>
    </Row>
    )
  }
}

class AppView extends Component {
    constructor(props) {
        super(props);
        this.state = {};  
        this._onChange = this._onChange.bind( this );
    }
    
    _onChange () {
        this.setState(getState());    
      }

    componentDidMount () {
      Store.addChangeListener(this._onChange);
      Actions.checkForCreds();
    }

    componentWillUnmount () {
        Store.removeChangeListener(this._onChange);
      }

      render() {
        let currScreen = null;
        let idSet = this.state.webIdForCreds?this.state.webIdForCreds:null;
   
        if ( !idSet) {
          currScreen = <Landing {...this.state} />;
        } else {
          let progress = this.state.userData.progress? this.state.userData.progress: null; 

          switch (progress) {  
              case 0: 
              currScreen = <Landing {...this.state} />;
              break;
              case 1:
              currScreen = <Location  {...this.state} />;
              break;
              case 2:
              currScreen = <CourtAppoint  {...this.state} />;
              break;
              case 3:
              currScreen = <ParentInfo  {...this.state} />;
              break;
              case 4:
              currScreen = <ChildInfo {...this.state} />;
              break;
              case 5: 
              currScreen = <VehicleInfo  {...this.state} />;
              break;
              case 6: 
              currScreen = <OrderSummary {...this.state} />;
              break;
              case 7: 
              currScreen = <Confirmation {...this.state} />;
              break;
              case 8: 
              currScreen = <SessionSchedule {...this.state} />;
              break;
              case 9: 
              currScreen = <SessionConfirm {...this.state} />;
              break;            
              default: 
              currScreen = <Landing {...this.state} />
              }
          }
    
        return (
        <div>
            <LogStat {...this.state} />
            <Header />
            <Container>
            <Row>
            <Col>
            <ProgBar {...this.state} />
            </Col>
            </Row>
            <Row>
            <Alerts {...this.state} />
            </Row>
            <Main {...this.state}>
            { currScreen }
            </Main>
    
        </Container>
        </div>
        )
      }
  }

export default AppView;