import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import PaypalCheckout from './Paypal.Checkout';

const styles = {
    disc: {
        "color": "red"
    },
    secBlock: {
        "display": "block",
        "margin": "12px 0px 12px 0px",
        "borderBottom": "1px white solid"
    },
    totBlock: {
        "display": "block",
        "margin": "12px 0px 12px 0px",
        "borderBottom": "2px white solid",
        "borderTop": "2px white solid"
    },    
    pricing: {
        "fontSize": "14px",
        "fontWeight": "bold"         
    },
    discount: {
        "color": "red"
    },
  paypal: {
    "textAlign": "right"
  },
}

class OrderSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showButton: true
        }
    }

    render() {

    const location = this.props.userData? this.props.userData.location? this.props.userData.location.Label : null : null; 
    const vehicles = this.props.userData? this.props.userData.vehicleInfo? this.props.userData.vehicleInfo : null : null; 
    const children = this.props.userData? this.props.userData.childInfo? this.props.userData.childInfo : null : null;
    const estimate = this.props.userData? this.props.userData.estimate? this.props.userData.estimate : null : null; 
    const totCost = estimate? estimate.totCost : null; 
    const discount = estimate? estimate.discount : null;
    const cert = estimate? estimate.certificate :  null;
    const netCost = estimate? estimate.netCost : null;
    const fee = estimate? estimate.fee : null;

    const discRow = ( disc ) => {
        if ( discount !== null ) {
            return ( 
            <Col md={8} sm={10} >
            <div style={ styles.secBlock } className="lighttext">
                <span>Volume Discount<span style={styles.discount} className="pull-right"> ${disc} </span></span>
            </div>
            </Col>
            )
        } else {
            return null;
        }
    }

    const certRow = ( cert ) => {
        if ( cert ) {
            return (
            <Col md={8} sm={10} >
            <div style={ styles.secBlock } className="lighttext">
            <span>Court Certificate<span  className="pull-right"> ${ cert } </span></span>
            </div>
            </Col>
            )
        } else {
            return null;
        }
    }    

    return (
        <Container>
        <h2 className="lighttext">Order Summary</h2>
        <Row>
            <Col md={8} sm={10} style={ styles.secBlock }>
            <p className="lighttext">
            <strong>Session Location</strong> <br/>
            { location }
            </p>
            </Col>
        </Row>

        <Row>
            <Col md={8} sm={10} className="lighttext">
            <strong>Base Session</strong> <span className="pull-right" ><strong>$75</strong></span> <br />
            (Includes one Seat Or Base Installation)
            </Col>
        </Row>

        <Row>
        <Col md={8} sm={10} className="lighttext" >
            { vehicles.map(( veh, vindex ) => {
                let chi = children.map (( child, cindex ) => {
                    return <li key={"c" + cindex}>Child { cindex +1 }  (Seat / Base Installation) : <span className="pull-right"><strong>{cindex + vindex === 0 ? '$0 (Included)' : '$25'}</strong></span> </li>
                });
                return (
                    <div key={"v" + vindex} style={styles.secBlock} ><strong>Vehicle { vindex + 1 }</strong>
                    <ul> { chi }</ul>
                </div>)
                }) 
            }
        </Col>
        </Row>
        <Row>
            <Col md={8} sm={10}>
            <div style={ styles.secBlock } className="lighttext">
            <strong>Transaction Fee  <span className="pull-right">${ fee }</span></strong>
            </div>
            </Col>
        </Row>

        <Row>

            { certRow( cert ) }

        </Row>

        <Row>
            <Col md={8} sm={10}>
            <div style={ styles.secBlock } className="lighttext">
            <strong>Sub-total  <span className="pull-right">${ totCost }</span></strong>
            </div>
            </Col>
        </Row>

        <Row>

            { discRow( discount ) }

        </Row>
        <Row>
            <Col md={8} sm={10}>
            <div style={ styles.totBlock } className="lighttext">
            <strong>Total for session <span className="pull-right">${ netCost }</span></strong>
            </div>
            </Col>
        </Row>
        <Row>
        <Col md={8} sm={10}>

        <div>
        
            <PaypalCheckout netcost={netCost} />
            
            <h3>WHY WE CHARGE A FEE</h3>
            <ul>
                <li>We come to your location (home, workplace, hospital, baby store, etc.) and take the time you need, which can take on-average 1.5 hours or more</li>
                <li>Our educational approach, part of the California Strategic Highway Safety Plan, has shown to be effective in giving you the skills necessary to secure your child properly </li>
                <li>Once you use the service you will have a Certified Technician available to answer any future question by phone</li>
                <li>We specialize with expectant parents and newborns – but are available for any age of child</li>
                <li>We use a training doll, and discuss specifics for newborn hospital discharge, as well as newborn safety tips</li>
                <li>We train all caregiver that transports the child, such as babysitter, grandparents, nanny, etc. </li>
                <li>We spend the time needed to make sure you are confident in securing who you love most - your child!</li>
            </ul>

            </div>

        </Col>
        </Row>
        </Container>

        )
    }
}

export default OrderSummary;