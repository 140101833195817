
import Constants from './Constants';
import Dispatcher from './Dispatcher';
import slotsData from './app.config.json';
import { Map, List, fromJS} from 'immutable';
import DataOps from '../utils/data.ops';
import { EventEmitter } from 'events';

const CHANGE_EVENT = 'change';

const display = ["Landing", "Location", "CourtAppoint", "ParentInfo", "ChildInfo", "VehicleInfo", "OrderSummary", "Confirmation", "ScheduleSession", "sessionConfirm"];

const slotLabels = slotsData.timeSlots.map( slot => {
  return slot.label;
});
const dayLabels = slotsData.validDays.map( day => {
  return day.substring( 0, 2 );
});

const sessionState = window.sessionStorage.getItem( '_procarSession' );

let prov = {};
let state = Map({});

if (sessionState) {
  let parsed = JSON.parse(sessionState);
  state = Map (fromJS(parsed));

  let soc = parsed.socialCreds;

  if ( soc !== undefined ) {
    DataOps.setIdentity( soc );
  }
} else {
  state = Map({
    identitySet: false,
    paypal: "",
    credentialsSet: false,
    webIdForCreds: false,
    slotLabels: List( slotLabels ),
    dayLabels: List( dayLabels ),
    status: Map({ style: "", message: ""}),
    loading: false,
//    childModalOn: false,
//    vehicleModalOn: false,
    socialCreds: Map( fromJS( prov )),
    socialAttr: Map( {} ),
    availArr: List(),
    userData: Map({
      progress: 0,
      courtData: Map({}),    
      estimate: Map({ totCost: null, discount: null, netCost: null, certificate: null, fee: null }),
      paymentData: Map({ payerID: null, paymentID: null, amount: null }),
      location: Map({Label: "", Lat:"", Lon: ""}),
      closest_city: Map({state: 'notSet'}),
      parentInfo: Map({Name: null, Email: null, Mobile: null, Contact:"email", Court: false }),
      childInfo: List(),
      vehicleInfo: List(),
      sessionDate: null,
      sessionSlotId: null,
      sessionScheduled: false,
      sessionInfo: Map({}),
      security: null,
      pastAppointments: List()
    })
  })
}

function saveSession ( payload ) {
  window.sessionStorage.setItem( '_procarSession', JSON.stringify ( payload ));
}

function clearSession () {
  window.sessionStorage.clear();
}

function paginate ( curr, vector ) {
  let newIdx = curr + vector;
  if ( newIdx > 0  && newIdx <= display.length ) {
    return newIdx;
  } else {
  return curr;
  }
}

function clearState ( provider ) {
  let prov = {}; 

  if ( provider ) {
    prov = provider;
  }

  return Map({
    identitySet: false,
    paypal: "",
    credentialsSet: false,
    webIdForCreds: false,    
    slotLabels: List( slotLabels ),
    dayLabels: List( dayLabels ),
    status: Map({ style: "", message: ""}),
    loading: false,
//    childModalOn: false,
//    vehicleModalOn: false,
    socialCreds: Map( fromJS( prov )),
    socialAttr: Map( {} ),
    availArr: List(),
    userData: Map({
      progress: 0,
      courtData: Map({}),    
      estimate: Map({ totCost: null, discount: null, netCost: null, certificate: null, fee: null }),
      paymentData: Map({ payerID: null, paymentID: null, amount: null }),
      location: Map({Label: "", Lat:"", Lon: ""}),
      closest_city: Map({state: 'notSet'}),
      parentInfo: Map({Name: null, Email: null, Mobile: null, Contact:"email", Court: false }),
      childInfo: List(),
      vehicleInfo: List(),
      sessionDate: null,
      sessionSlotId: null,
      sessionScheduled: false,
      sessionInfo: Map({}),
      security: null,
      pastAppointments: List()
    })
  })
}

function SYNCED_DATA_GOT (payload) {
  let synced = payload? payload.Item? payload.Item.evalform : null : null; 
  if (synced) {
    state = state.setIn([ 'userData' ], fromJS(synced));

    saveSession( state.toJSON() );
  }
}

  function NAV_BACK() {
    state = state.setIn([ 'status', "style" ], "");
    state = state.setIn([ 'status', "message" ], "");
    state = state.setIn([ 'userData', 'progress' ], paginate ( state.getIn([ 'userData', 'progress' ]), -1 ));
    saveSession(  state.toJSON() );
    DataOps.syncUserData ( state.get( 'userData' ).toJSON() );
  }

  function NAV_FWD () {
    state = state.setIn([ 'status', "style" ], "");
    state = state.setIn([ 'status', "message" ], "");
    if ( state.get('socialAttr') !== undefined ) {
      state = state.setIn([ 'userData', 'progress' ], paginate ( state.getIn([ 'userData', 'progress' ]), 1 ));
      saveSession(  state.toJSON() );
      DataOps.syncUserData ( state.get( 'userData' ).toJSON() );
    }
  }

  function COURT_SELECT (isSelected) {
    state = state.setIn([ 'userData', 'parentInfo', 'Court'], isSelected );
    if ( !isSelected ) {
      state = state.setIn([ 'userData', 'courtData' ], new Map({}));
    }
  }

  function COURT_DATA_SAVE (payload) {
      state = state.setIn([ 'userData', 'courtData' ], fromJS(payload));
      saveSession(  state.toJSON() );
      DataOps.syncUserData ( state.get( 'userData' ).toJSON() );
      state = state.setIn([ 'userData', 'progress' ], paginate ( state.getIn([ 'userData', 'progress' ]), 1 ));
  }
    
  function CONFIRM_PAYMENT (payId, confId, amt) {
    state = state.setIn([ 'userData', 'paymentData', 'payerID' ], payId );
    state = state.setIn([ 'userData', 'paymentData', 'paymentID' ], confId );
    state = state.setIn([ 'userData', 'paymentData', 'amount' ], amt );

    state = state.setIn([ 'status', "style" ], "");
    state = state.setIn([ 'status', "message" ], "");
    state = state.setIn([ 'userData', 'progress' ], 7 );

    DataOps.confirmPay ( confId, payId, amt );
  }
    
  function ORDER_CONFIRMED () {
    saveSession(  state.toJSON() );
    DataOps.syncUserData ( state.get( 'userData' ).toJSON() );
  }

  function EVAL_SAVE () {
    state = state.setIn ( ['status', 'style'], "info");
    state = state.setIn ( ['status', 'message'], "Saving Data.");
    DataOps.completeEval ( state.get( 'userData' ).toJSON() );
  }

  function EVAL_SAVED (evalObj) {
    let estObj = evalObj.Attributes.evalform.estimate;
    let pin = evalObj.Attributes.evalform.security;
    state = state.setIn([ 'userData', 'estimate', 'totCost' ], estObj.totalCost );
    state = state.setIn([ 'userData', 'estimate', 'discount' ], estObj.discount );
    state = state.setIn([ 'userData', 'estimate', 'netCost'], estObj.netCost );
    state = state.setIn([ 'userData', 'estimate', 'fee'], estObj.fee );
    state = state.setIn([ 'userData', 'estimate', 'certificate'], estObj.certificate );
    state = state.setIn([ 'userData', 'security'], pin );              

    state = state.setIn ( ['status', 'style'], "success");
    state = state.setIn ( ['status', 'message'], "Your data was successfully saved");

    state = state.setIn([ 'userData', 'progress' ], paginate ( state.getIn([ 'userData', 'progress' ]), 1 ));

    DataOps.syncUserData ( state.get( 'userData' ).toJSON() );        
    saveSession( state.toJSON() );
  }


  function SELECT_DAY (dayStr) {
    let closest = state.getIn(['userData', 'closest_city', 'name']); 
    state = state.setIn( ['userData', 'sessionSlotId'], null );
    state = state.setIn( ['userData', 'sessionDate'], dayStr );
    state = state.setIn ( ['status', 'style'], "info");
    state = state.setIn ( ['status', 'message'], "Checking Availability.");
    DataOps.getAvail({ date: dayStr, location: closest, action: "getavail" });
  }

  function SCHEDULE_SESSION (schedObj) {
    let scheduled = state.getIn(['userData', 'sessionSlotId']);
    if (!scheduled) { 
      state = state.setIn(['userData', 'sessionScheduled'], true);
      state = state.setIn(['userData', 'sessionDate'], schedObj.date);
      state = state.setIn(['userData', 'sessionSlotId'], schedObj.slot);
      let loc =  state.getIn(['userData', 'closest_city', 'name']); 
      let act  = "schedule";
      let schedulePayload = Object.assign({}, schedObj, {location: loc, action: act})

      DataOps.scheduleSession( schedulePayload );
    }
  }

  function SESSION_CONFIRMED (payload) {
    let sessData = JSON.parse(payload.data);
    state = state.setIn( ['userData', 'sessionInfo'], fromJS( sessData.Payload ));
    if ( state.get('socialAttr') !== undefined ) {
      state = state.setIn([ 'userData', 'progress' ], paginate ( state.getIn([ 'userData', 'progress' ]), 1 ));
      saveSession(  state.toJSON() );
      DataOps.syncUserData ( state.get( 'userData' ).toJSON() );
      return state;
    }
  }

  function AVAILABILITY_GOT (payload) {
    let avail = JSON.parse( payload.data );
    state = state.set('availArr', fromJS(avail.Payload));        
    state = state.setIn ( ['status', 'style'], "");
    state = state.setIn ( ['status', 'message'], "");
  }

  function UPDATE_DATA (payload) {
    state = state.setIn( payload.Path, payload.Value );
  }

  function UPDATE_PARENT (payload) {
    state = state.setIn(['userData', 'parentInfo', 'Name'], payload.Name);
    state = state.setIn(['userData', 'parentInfo', 'Email'], payload.Email);
    state = state.setIn(['userData', 'parentInfo', 'Mobile'], payload.Mobile);
    state = state.setIn(['userData', 'parentInfo', 'Mobile'], payload.Contatc);
    state = state.setIn([ 'userData', 'progress' ], paginate ( state.getIn([ 'userData', 'progress' ]), 1 ));
    saveSession(state.toJSON());
    DataOps.syncUserData ( state.get( 'userData' ).toJSON() );
    
  }

  function UPDATE_LOCATION (payload) {
    state = state.set('loading', true);
    state = state.setIn( payload.Path, payload.Value);
    DataOps.getClosestCity(payload.Value);
  }
  
  function CLOSEST_CITY_GOT (payload) {
    state = state.set('loading', false);
    state = state.setIn(['userData', 'closest_city'], fromJS(payload));
  }

  function ADD_CHILD (payload) {
    let childObj = Object.assign({}, {
      age: payload.age === ""?  null : payload.age,
      height: payload.height ===""?  null : payload.height,
      weight: payload.weight ===""?  null : payload.weight,
      unborn: payload.unborn,
      units: payload.units
    })
    
    childObj = Map(fromJS(childObj));
    
    state = state.updateIn(['userData', 'childInfo'], arr => arr.push( childObj));
    saveSession(  state.toJSON() );
  }

  function REM_CHILD (idx) {
    state = state.deleteIn( [ 'userData', 'childInfo', idx ]);
    saveSession( state.toJSON());
  }

  function ADD_VEHICLE (payload) {
    let vehObj = Map(fromJS(payload));
    state = state.updateIn( [ 'userData', 'vehicleInfo'], arr => arr.push( vehObj));
    saveSession( state.toJSON());
  }

  function REM_VEHICLE (idx) {
    state =  state.deleteIn( [ 'userData', 'vehicleInfo', idx ]);
    saveSession( state.toJSON());
  }

  function SOCIAL_LOGIN (payload) {
    let creds = {
      provider: payload.provider,
      token: payload.token
    }
    DataOps.setIdentity( creds );        
    state = state.setIn([ 'status', "style" ], "");
    state = state.setIn([ 'status', "message" ], "");
    state = state.setIn([ 'userData', 'progress' ], 1 );
    state = state.set( 'socialAttr', fromJS( payload.attr ));
    state = state.set( 'socialCreds', fromJS( creds )); 
    saveSession(  state.toJSON() );
  }

  function GEN_STATUS (style, err) {
    state = state.setIn( ['status', 'style'], style );
    state = state.setIn( ['status', 'message'], err );
  }

  function CREDENTIALS_GOT (payload) {
      let socEmpty = state.get( 'socialCreds' ).isEmpty();

      if ( payload.hasOwnProperty( 'SessionToken' )) {
        if ( !socEmpty ) {
          let creds = state.get( 'socialCreds' ).toJSON();            
          DataOps.setIdentity({[ creds.provider ] : creds.token});
        }
      }
      state = state.set( 'credentialsSet', true );
    }

  function CREDENTIALS_FAILED (style, err) {
    state = clearState();
    state = state.setIn( ['status', 'style'], style );
    state = state.setIn( ['status', 'message'], err );
    state = state.setIn([ 'userData', 'progress' ], 0 );

    saveSession( state.toJSON() );
  }

  function IDENTITY_SET (paypalKey) {
    state = state.set( 'identitySet', true );
    state = state.set( 'credentialsSet', true );
    state = state.set( 'webIdForCreds', true );
    state = state.set('paypal', paypalKey);
    state = state = state.setIn([ 'userData', 'progress' ], 1 );
    
    DataOps.getSyncedData();
  }

  function IDENTITY_FAILED (style, err) {
    state = state.setIn(['status', 'style'], style );
    state = state.setIn(['status', 'message'], err );
    state = state.setIn(['userData', 'progress' ], 0 );
    state = state.set( 'socialAttr', new Map());
  }
  
  function LOGOUT () {
    DataOps.logOut( state.get('socialCreds').toJS() );
  }

  function LOGGED_OUT () {
    let prv = state.get('socialCreds').toJS();

    clearSession();
    state = clearState( prv );
  }

  function CHECK_FOR_CREDS () {
    DataOps.checkForCreds();
  }

  function MAKE_NEW_APPOINTMENT () {
    let archive = state.get( 'userData' ).toJSON();
    let hasPrevious = state.getIn(['userData', 'pastAppointments']);

    if (!hasPrevious) {
      state = state.setIn(['userData', 'pastAppointments'], List());
    }
     
    state = state.updateIn(['userData', 'pastAppointments'], arr => arr.push( archive));
    DataOps.syncUserData ( archive);

    state = state.setIn([ 'userData', 'progress' ], 1 );
    state = state.setIn([ 'userData', 'courtData' ], Map({}) );
    state = state.setIn([ 'userData', 'estimate' ], Map({ totCost: null, discount: null, netCost: null, certificate: null, fee: null }));
    state = state.setIn([ 'userData', 'paymentData' ], Map({ payerID: null, paymentID: null, amount: null }));
    state = state.setIn([ 'userData', 'location' ], Map({Label: "", Lat:"", Lon: ""}));
    state = state.setIn([ 'userData', 'closest_city' ], Map({state: 'notSet'}));
    state = state.setIn([ 'userData', 'parentInfo' ], Map({Name: null, Email: null, Mobile: null, Contact:"email", Court: false }));
    state = state.setIn([ 'userData', 'childInfo' ], List());
    state = state.setIn([ 'userData', 'vehicleInfo' ], List());
    state = state.setIn([ 'userData', 'sessionDate' ], null);
    state = state.setIn([ 'userData', 'sessionSlotId' ], null);
    state = state.setIn([ 'userData', 'sessionScheduled' ], false);
    state = state.setIn([ 'userData', 'sessionInfo' ], Map({}));
    state = state.setIn([ 'userData', 'security' ], null);

    saveSession( state.toJSON() );

  }

    const Store = Object.assign({}, EventEmitter.prototype, {
      getState: function () {
          return state.toJS();
      },    
      emitChange: function() {
          this.emit(CHANGE_EVENT);
      },
      addChangeListener: function(callback) {
          this.on(CHANGE_EVENT, callback);
      },
      removeChangeListener: function(callback) {
          this.removeListener(CHANGE_EVENT, callback);
      }
    });

  Dispatcher.register(function(action) {
    
    console.log(action.type)
    console.dir(state.toJS());
  
    switch(action.type) {

      case Constants.SYNCED_DATA_GOT:
        SYNCED_DATA_GOT(action.payload)
        Store.emitChange();
        break;          

      case Constants.NAV_BACK: 
        NAV_BACK();
        Store.emitChange();
        break;          

      case Constants.NAV_FWD: 
        NAV_FWD();
        Store.emitChange();
        break;          

      case Constants.COURT_SELECT: 
        COURT_SELECT(action.state);
        Store.emitChange();
        break;          

      case Constants.COURT_DATA_SAVE:
        COURT_DATA_SAVE(action.payload);
        Store.emitChange();
        break;          
      
      case Constants.CONFIRM_PAYMENT:
        CONFIRM_PAYMENT(action.payId, action.confId, action.amount);
        Store.emitChange();
        break;          
      
      case Constants.ORDER_CONFIRMED:
        ORDER_CONFIRMED()
        Store.emitChange();
        break;          

      case Constants.EVAL_SAVE:
        EVAL_SAVE();
        Store.emitChange();
        break;          

      case Constants.EVAL_SAVED:
        EVAL_SAVED(action.evalObj);
        Store.emitChange();
        break;          

      case Constants.SELECT_DAY:
        SELECT_DAY(action.dayStr);
        Store.emitChange();
        break;          

      case Constants.SCHEDULE_SESSION:
        SCHEDULE_SESSION(action.schedObj);
        Store.emitChange();
        break;          

      case Constants.SESSION_CONFIRMED:
        SESSION_CONFIRMED(action.payload)
        Store.emitChange();
        break;          
  
      case Constants.AVAILABILITY_GOT:
        AVAILABILITY_GOT(action.payload);
        Store.emitChange();
        break;          
  
      case Constants.UPDATE_DATA:
        UPDATE_DATA(action.payload)
        Store.emitChange();
        break;          
  
      case Constants.UPDATE_LOCATION:
        UPDATE_LOCATION(action.payload);
        Store.emitChange();
        break;          
      
      case Constants.CLOSEST_CITY_GOT:
        CLOSEST_CITY_GOT(fromJS(action.payload));
        Store.emitChange();
        break;          

      case Constants.ADD_CHILD:
        ADD_CHILD(action.payload);
        Store.emitChange();
        break;          

      case Constants.REM_CHILD:
        REM_CHILD(action.idx);
        Store.emitChange();
        break;          

      case Constants.ADD_VEHICLE:
        ADD_VEHICLE(action.payload);
        Store.emitChange();
        break;          

      case Constants.REM_VEHICLE:
        REM_VEHICLE(action.idx);
        Store.emitChange();
        break;          

      case Constants.SOCIAL_LOGIN:
        SOCIAL_LOGIN(action.payload);
        Store.emitChange();
        break;          

      case Constants.GEN_STATUS:
        GEN_STATUS(action.style, action.message);
        Store.emitChange();
        break;          

      case Constants.CREDENTIALS_GOT:
        CREDENTIALS_GOT(action.payload)
        Store.emitChange();
        break;          

      case Constants.CREDENTIALS_FAILED:
        CREDENTIALS_FAILED(action.style, action.err)
        Store.emitChange();
        break;          

      case Constants.IDENTITY_SET:
        IDENTITY_SET(action.payload);
        Store.emitChange();
        break;          

      case Constants.IDENTITY_FAILED:
        IDENTITY_FAILED(action.style, action.err);
        Store.emitChange();
        break;          
      
      case Constants.LOGOUT:
        LOGOUT();
        Store.emitChange();
        break;          

      case Constants.LOGGED_OUT:
        LOGGED_OUT();
        Store.emitChange();
        break;          

      case Constants.CHECK_FOR_CREDS:
        CHECK_FOR_CREDS();
        Store.emitChange();
        break;

      case Constants.UPDATE_PARENT:
        UPDATE_PARENT(action.payload);
        Store.emitChange();
        break;
      
      case Constants.MAKE_NEW_APPOINTMENT:
        MAKE_NEW_APPOINTMENT();
        Store.emitChange();
        break;
    
      default:
      Store.emitChange();
    }
})

export default Store;