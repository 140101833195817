import React, { Component } from 'react';
import Actions from '../data/Actions';
import { Alert } from 'react-bootstrap';

const styles = {
    spanBtn: {
        "color": "blue",
        "cursor": "pointer"
    }
}

class SessionConfirm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            postAppt: new Date() > new Date (this.props.userData.sessionDate)
        }
        this.handleNewAppointment = this.handleNewAppointment.bind(this);
    }

    handleNewAppointment () {
        Actions.makeNewAppointment()
    }

    render() {

    const sessDate = this.props.userData.sessionDate;
    const sessDateStr = new Date ( sessDate ).toString();
    const loc = this.props.userData.location.Label;
    const sessSlotId = parseInt(this.props.userData.sessionSlotId, 10);

    const sessSlot = this.props.slotLabels[sessSlotId - 1];
    const cpstInfo = this.props.userData.sessionInfo;
    const pinNumber = this.props.userData.security;
    var newAppt = null;

    if (this.state.postAppt) {
        newAppt = <p> 
            
            <h2 className="lighttext">Need a new appointment?</h2>

            <Alert variant="info">
            If you wish to make a new appointment, please <span role="link" style={styles.spanBtn} 
            onClick={this.handleNewAppointment}>click here</span>.
            The current appointment will be archived.
            </Alert>
            </p>
    }

    return (
        <div id="userInfoBlock">
        <h2 className="lighttext" >Confirmed Appointment</h2>
        
        <p className="lighttext"><strong>Date</strong>: { sessDateStr }</p>
        <p className="lighttext"><strong>Time</strong>: { sessSlot }</p>
        <p className="lighttext"><strong>Location</strong>: { loc }</p>
        <p className="lighttext"><strong>Specialist</strong>: { cpstInfo.name }</p>

        { !this.state.postAppt &&
            <div>
                <p className="lighttext"><strong>Email</strong>: { cpstInfo.email }</p>
                <p className="lighttext"><strong>phone</strong>: { cpstInfo.phone }</p>
            </div>
        }


        <p className="lighttext"><strong>Your secret PIN number (required during the appointment):  
        {"  "}{ pinNumber }</strong></p>

        {newAppt}

        </div>

        )
    }
}

export default SessionConfirm;