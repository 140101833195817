import React, { Component } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import Toggle  from 'react-toggle';
import Actions from '../data/Actions';
import "react-toggle/style.css";

const initFields = {stuName: "", address: "", dob: "", issueCourt: "", caseNum: "",
licenseNum: "", insurState: "", completeDate: "", courtDate: ""}

const styles = {
    btnGap: {
        "margin": "25px 0px 25px 0px"
    },
    inline: {
        "display": "table-cell",
        "verticalAlign": "middle",
        "height": "50px"
    },
    togglespan: {
        "verticalAlign": "top",
        "marginLeft": "15px"
    },
    hidden: {
        "display": "none"
    }
}
class CourtAppoint extends Component {
  constructor(props) {
      super(props);
      this.state = {
        validated: false,
        isCourtAppointed: false,
        fields: initFields
      }
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleCourtSelect = this.handleCourtSelect.bind(this);
  }

  handleChange (e) {
    let newFields = Object.assign({}, this.state.fields, {[e.target.id] : e.target.value})
    this.setState({fields: newFields})
  }

  handleCourtSelect() {
    let courtState = this.state.isCourtAppointed
    this.setState({isCourtAppointed: !courtState});
    Actions.courtSelect(!courtState)
  }

  componentDidUpdate (prevProps) {
    if(prevProps.userData.parentInfo.Court !== this.props.userData.parentInfo.Court) {
      this.setState({isCourtAppointed: this.props.userData.parentInfo.Court})
    }
  }

  componentDidMount () {
    if (this.props.userData) {
      this.setState({isCourtAppointed : this.props.userData.parentInfo.Court});
      console.log("DID MOUNT WITH " + this.props.userData.parentInfo.Court)
      if (Object.keys(this.props.userData.courtData).length > 0) {
        this.setState({fields: this.props.userData.courtData});
      }
    }
  }

  componentWillUnmount () {
    this.setState({fields: initFields});
  }

  handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity()) {
      let payload = this.state.isCourtAppointed? Object.assign({}, this.state.fields) : {};
      Actions.courtDataSave(payload);
    } 
    this.setState({ validated: true });
  }

  render() {
    const { validated } = this.state;

  return (
      <div>
      <h2 className="lighttext">Parent / Care-giver Information</h2>

      <Form noValidate validated={validated} onSubmit={e => this.handleSubmit(e)}>
      <div style={ styles.inline }>
          <label> 
          <Toggle
          id='Court'
          defaultChecked={this.state.isCourtAppointed}
          onChange={this.handleCourtSelect} />
          <span className="lighttext" style={ styles.togglespan } >Is this a Court Appointed Session?</span>
          </label>
        </div>

        <div style={this.state.isCourtAppointed? null : styles.hidden } >
        <Form.Group controlId="stuName" >
          <Form.Label className="lighttext">Student Name</Form.Label>
          <Form.Control type="text" onChange={this.handleChange} value={this.state.fields.stuName}  required={this.state.isCourtAppointed} />
          <Form.Control.Feedback type="invalid">
              Please provide a valid name.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="address"  >
          <Form.Label className="lighttext">Address</Form.Label>
          <Form.Control type="text" onChange={this.handleChange} value={this.state.fields.address} required={this.state.isCourtAppointed} />
          <Form.Control.Feedback type="invalid">
              Please provide a valid address.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="dob"  >
          <Form.Label className="lighttext">Date of Birth</Form.Label>
          <Form.Control type="text" onChange={this.handleChange} value={this.state.fields.dob} required={this.state.isCourtAppointed} />
          <Form.Control.Feedback type="invalid">
              Please provide a valid date of birth.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="issueCourt"  >
          <Form.Label className="lighttext">Issuing Court</Form.Label>
          <Form.Control type="text" onChange={this.handleChange} value={this.state.fields.issueCourt} required={this.state.isCourtAppointed} />
          <Form.Control.Feedback type="invalid">
              Please provide a valid Court name.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="caseNum"  >
          <Form.Label className="lighttext">Citation / Case Numbe</Form.Label>
          <Form.Control type="text" onChange={this.handleChange} value={this.state.fields.caseNum} required={this.state.isCourtAppointed}/>
          <Form.Control.Feedback type="invalid">
              Please provide a valid case number.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="licenseNum" >
          <Form.Label className="lighttext">Driver's License Number</Form.Label>
          <Form.Control type="text" onChange={this.handleChange} value={this.state.fields.licenseNum} required={this.state.isCourtAppointed}/>
          <Form.Control.Feedback type="invalid">
              Please provide a valid license number.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="insurState"  >
          <Form.Label className="lighttext">License Insurance State</Form.Label>
          <Form.Control type="text" onChange={this.handleChange} value={this.state.fields.insurState} required={this.state.isCourtAppointed}/>
          <Form.Control.Feedback type="invalid">
              Please provide a valid state name.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="completeDate"  >
          <Form.Label className="lighttext">Traffic Diversion Completion Date</Form.Label>
          <Form.Control type="text" onChange={this.handleChange} value={this.state.fields.completeDate} required={this.state.isCourtAppointed}/>
          <Form.Control.Feedback type="invalid">
              Please provide a valid date.
          </Form.Control.Feedback>
        </Form.Group>        

        <Form.Group controlId="courtDate"  >
          <Form.Label className="lighttext">Traffic Diversion Court Due Date</Form.Label>
          <Form.Control type="text" onChange={this.handleChange} value={this.state.fields.courtDate} required={this.state.isCourtAppointed}/>
          <Form.Control.Feedback type="invalid">
              Please provide a valid date.
          </Form.Control.Feedback>
        </Form.Group>

        </div>

          <Row>
            <Col>
          <p style={styles.btnGap}>
            <Button variant="light" onClick={Actions.navBack}> &larr; Go Back</Button>
            <Button variant="primary" type="submit" className="float-right" >Next</Button>
          </p>
          </Col>
          </Row>

      </Form>
      </div>

    )
  }
}

export default CourtAppoint;