import ApiOps from './api.ops';
import Dispatcher from '../data/Dispatcher';

const DataOps = {

    confirmPay ( payId, payerId, amt ) {
        let payObj = Object.assign({}, { paymentID: payId, payerID: payerId, amount: amt});

        ApiOps.apiFetch( 'PUT', 'payconfirm', payObj )
        .then( token => {
            Dispatcher.dispatch({
                type: 'ORDER_CONFIRMED',
                err: null,
                token
            })
        })
        .catch( err => {
            Dispatcher.dispatch({
                type:'GEN_STATUS',
                style: 'danger',
                err: err.message
            })
        })
    },

    logOut ( provider ) {
        ApiOps.logOut( provider )
        .then( resp => {
            Dispatcher.dispatch({
                type: 'LOGGED_OUT'
            })
        })
        .catch( err => {
            console.log( err )
            Dispatcher.dispatch({
                type:'GEN_STATUS',
                style: 'danger',
                err: 'Unable to log user out.'
            })            
        })
    },

    checkForCreds () {
        ApiOps.getSession()
        .then(sess => {
            if (!sess) return;
            else return ApiOps.getCreds()
        })
        .then(creds => {
            if (!creds) {
                return;
            }          
            return ApiOps.initCreds(creds);
        })
        .then( credsObj => {
            Dispatcher.dispatch({
                type: 'CREDENTIALS_GOT',
                payload: credsObj
            })
        })
        .catch( err => {
            console.log(err);
            return;
        })
    },
   
    setIdentity ( socCreds ) {
        ApiOps.setIdentity( socCreds )        
        .then( paypalKey => {
            Dispatcher.dispatch({
                type: 'IDENTITY_SET',
                payload: paypalKey
            })
        })
        .catch( err => {
            Dispatcher.dispatch({
                type: 'IDENTITY_FAILED',
                style: 'danger',
                err: err.message
            })
        })
    },

    syncUserData ( payload ) {
        ApiOps.apiFetch ( 'PUT', 'syncdataput', payload )
        .then( () => {
            Dispatcher.dispatch({
                type: 'SYNCED_DATA_PUT',
            })
        })
        .catch( err => {
            Dispatcher.dispatch({
                type:'GEN_STATUS',
                style: 'danger',
                err: err.message
            })
        })
    },

    getSyncedData () {
        ApiOps.apiFetch ( 'GET', 'syncdataget' )
        .then( usrdat => {
            Dispatcher.dispatch({
                type: 'SYNCED_DATA_GOT',
                payload: usrdat
            })
        })
        .catch( err => {
            Dispatcher.dispatch({
                type:'GEN_STATUS',
                style: 'danger',
                err: err.message
            })
        })
    },

    setCredentials () {
        ApiOps.setCredentials()
        .then( creds => {
            Dispatcher.dispatch({
                type: 'CREDENTIALS_GOT',
                payload: creds
            })
        })
        .catch( err => {
            console.dir( err )
            Dispatcher.dispatch({
                type: 'CREDENTIALS_FAILED',
                style: 'danger',
                err: err.message
            })
        })
    },

    getAvail ( dataObj ) {
        ApiOps.apiFetch ( 'GET', 'scheduler', dataObj )
        .then( availObj => {
            Dispatcher.dispatch({
                type: 'AVAILABILITY_GOT',
                payload: availObj
            })
        }).catch( err => {
            console.dir ( err )
            Dispatcher.dispatch({
                type: 'GEN_STATUS',
                style: 'danger',
                err: err.message
            })
        })
    },

    scheduleSession ( schObj ) {
        ApiOps.apiFetch ( 'GET', 'scheduler', schObj )
        .then( data => {
            console.dir(data)
            Dispatcher.dispatch({
                type: 'SESSION_CONFIRMED',
                payload: data
            })
        }).catch( err => {
            console.dir ( err )
            Dispatcher.dispatch({
                type: 'GEN_STATUS',
                style: 'danger',
                err: err.message
            })
        })
    },

    getClosestCity ( locObj ) {
        ApiOps.apiFetch ( 'GET', 'proximity', locObj )
        .then( resp => {
            let rawResp = resp.data;
            let locObj = JSON.parse(rawResp);
            Dispatcher.dispatch({
                type: 'CLOSEST_CITY_GOT',
                payload: locObj.Payload
            })
        }).catch( err => {
            console.log(err)
            Dispatcher.dispatch({
                type: 'GEN_STATUS',
                style: 'danger',
                err: err
            })
        })
    },


    completeEval ( dataObj ) {
        ApiOps.apiFetch ( 'PUT', 'evalsave', dataObj )     
        .then( data => {
            Dispatcher.dispatch({
                type: 'EVAL_SAVED',
                evalObj: data
            })
        })
        .catch( err => {
            Dispatcher.dispatch({
                type: 'GEN_STATUS',
                style: 'danger',
                err: err.message
            })
        })
    }
}

export default DataOps;
