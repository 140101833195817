
import Constants from './Constants';
import Dispatcher from './Dispatcher';

const Actions = {
  logUserIn( creds ) {
    Dispatcher.dispatch({
      type: Constants.LOG_USER_IN,
      creds,
    });
  },
  updateData ( payload ) {
    Dispatcher.dispatch({
      type: Constants.UPDATE_DATA,
      payload
    })
  },
  socialLogin ( payload ) {
    Dispatcher.dispatch({
      type: Constants.SOCIAL_LOGIN,
      payload
    })
  },
  saveUserInfo( name, loc, contactMeth, phone ) {
    Dispatcher.dispatch({
      type: Constants.SAVE_USER_INFO,
      name, loc, contactMeth, phone
    });
  },
  evalSave ( ) {
    Dispatcher.dispatch({
      type: Constants.EVAL_SAVE
    })
  },
  evalSaved ( evalObj ) {
    Dispatcher.dispatch({
      type: Constants.EVAL_SAVED,
      evalObj
    });
  },
  selectDay( dayStr ) {
    Dispatcher.dispatch({
      type: Constants.SELECT_DAY,
      dayStr
    });
  },
  selectSlot( idx ) {
    Dispatcher.dispatch({
      type: Constants.SELECT_SLOT,
      idx
    });
  },
  scheduleSession( schedObj ) {
    Dispatcher.dispatch({
      type: Constants.SCHEDULE_SESSION,
      schedObj
    });
  },
  confirmOrder( ) {
    Dispatcher.dispatch({
      type: Constants.CONFIRM_ORDER
    });
  },
  navFwd() {
    Dispatcher.dispatch({
      type: Constants.NAV_FWD
    });
  },
  navBack( ) {
    Dispatcher.dispatch({
      type: Constants.NAV_BACK
    });
  },
  addChild(payload) {
    Dispatcher.dispatch({
      type: Constants.ADD_CHILD,
      payload
    });
  },
  remChild( idx ) {
    Dispatcher.dispatch({
      type: Constants.REM_CHILD,
      idx
    });
  },
  addVehicle(payload) {
    Dispatcher.dispatch({
      type: Constants.ADD_VEHICLE,
      payload
    });
  },
  remVehicle( idx ) {
    Dispatcher.dispatch({
      type: Constants.REM_VEHICLE,
      idx
    });
  },
  genStatus ( style, message ) {
    Dispatcher.dispatch({
      type: Constants.GEN_STATUS,
      style, message
    })
  },
  confirmPayment ( confId, payId, amount ) {
    Dispatcher.dispatch({
      type: Constants.CONFIRM_PAYMENT,
      confId, payId, amount
    })
  },
  paymentConfirmed( token ) {
    Dispatcher.dispatch({
      type: Constants.ORDER_CONFIRMED,
      token
    });
  }, 
  availabilityGot ( payload ) {
    Dispatcher.dispatch({
      type: Constants.AVAILABILITY_GOT,
      payload
    })
  },
  courtDataSave (payload){
    Dispatcher.dispatch({
      type: Constants.COURT_DATA_SAVE,
      payload
    });
  },    
  courtSelect ( state ){
    Dispatcher.dispatch({
      type: Constants.COURT_SELECT,
      state
    });
  },
  updateLocation(payload) {
    Dispatcher.dispatch({
      type: Constants.UPDATE_LOCATION,
      payload
    });    
  },
  updateParent(payload) {
    Dispatcher.dispatch({
      type: Constants.UPDATE_PARENT,
      payload
    })
  },
  logOut (){
    Dispatcher.dispatch({
      type: Constants.LOGOUT
    });
  },
  checkForCreds(){
    Dispatcher.dispatch({
      type: Constants.CHECK_FOR_CREDS
    });
  },
  makeNewAppointment(){
    Dispatcher.dispatch({
      type: Constants.MAKE_NEW_APPOINTMENT
    });
  }
};

export default Actions;