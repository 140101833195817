
import React, { Component } from 'react';
import Actions from '../data/Actions';
import { Button } from 'react-bootstrap';

const styles = {
  btnGap: {
  "margin": "25px 0px 25px 0px"
  }
}

class Confirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render () {
        const payComplete = this.props.userData? this.props.userData.paymentData? this.props.userData.paymentData.paymentID : null : null;
        let confBody = null;
        let confHeader = null;

        if ( !payComplete ) {
            confHeader = "Your Paypal payment failed";
            confBody = "Please go back and try the payment again in order to proceed to scheduling."
        } else {
            confHeader = "Your Paypal payment was processed"
            confBody = "Please proceed to scheduling."  
        }

        return (
            <div>
            <h2 className="lighttext">{ confHeader }</h2>
            <h4 className="lighttext">{ confBody }</h4>

            <Button variant="primary" style={ styles.btnGap } className="pull-right" disabled={ !payComplete } 
            onClick={Actions.navFwd} > Next</Button>
            </div>
        )
    }
}

export default Confirmation;