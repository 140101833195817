import React, { Component } from 'react';
import { Form, Button, Col, Row} from 'react-bootstrap';
import "react-toggle/style.css";
import Actions from '../data/Actions';

const initFields = {Name: "", Email: "", Mobile: "", Contact: "email"}

class ParentInfo extends Component {
  constructor(props) {
      super(props);
      this.state = {
        validated: false,
        fields: initFields
      }
      this.handleChange = this.handleChange.bind(this);
      this.handleContactPref = this.handleContactPref.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
  }
  
  componentDidMount () {
    let parentData = this.props.userData? this.props.userData.parentInfo : null; 
    if (parentData) {
      let newFields = {
        Name: parentData.Name || "",
        Email: parentData.Email || "",
        Mobile: parentData.Mobile || "",
        Contact: parentData.Contact || "" 
      }
      this.setState({fields: newFields});
    }
  }
  
  handleChange (e) {
    let newFields = Object.assign({}, this.state.fields, {[e.target.id] : e.target.value})
    this.setState({fields: newFields})
  }

  handleContactPref (e) {
    let newFields = Object.assign({}, this.state.fields, {Contact: e.target.id})
    this.setState({fields: newFields});
  }

  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      Actions.updateParent(this.state.fields);
    }
    this.setState({ validated: true });
  }

  render() {
  return (
    <div>
      <h2 className="lighttext">Parent / Care-giver Information</h2>

        <Form noValidate validated={this.state.validated} onSubmit={e => this.handleSubmit(e)}>
        <Form.Group controlId="Name" >
          <Form.Label className="lighttext">Full Name</Form.Label>
          <Form.Control type="text" onChange={this.handleChange} value={this.state.fields.Name} required />
          <Form.Control.Feedback type="invalid">Please enter your full name.</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="Email" >
          <Form.Label className="lighttext">Email Address</Form.Label>
          <Form.Control type="text" onChange={this.handleChange} value={this.state.fields.Email} required />
          <Form.Control.Feedback type="invalid">Please enter your street address.</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="Mobile"  >
          <Form.Label className="lighttext">Mobile Phone</Form.Label>
          <Form.Control type="text" onChange={this.handleChange} value={this.state.fields.Mobile} required />
          <Form.Control.Feedback type="invalid">Please enter your Mobile phone number.</Form.Control.Feedback>
        </Form.Group>

        <p className="lighttext">Preferred contact method</p>

        <fieldset>
        <Form.Group controlId="Contact">
        <Form.Check type='radio' label="Email" custom="true" checked={ this.state.fields.Contact === "email" } name="contactPref" id="email" className="lighttext" inline onChange={this.handleContactPref} />
        <Form.Check type='radio' label="SMS" custom="true" checked={ this.state.fields.Contact === "text" } name="contactPref" id="text" className="lighttext" inline onChange={this.handleContactPref} />
        </Form.Group>
        </fieldset>

        <Row>
          <Col>
          <Button variant="light" onClick={Actions.navBack} > &larr; Go Back</Button>
          <Button variant="primary" type="submit" className="float-right" >Next</Button>

          </Col>
        </Row>
        </Form>
      </div>

    )
  }
}

export default ParentInfo;