
import React, { Component } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import Geosuggest from 'react-geosuggest';
import Actions from '../data/Actions';

const styles = {
  btnGap: {
    "margin": "25px 0px 25px 0px"
  },
  closest: {
    "marginTop": "25px",
    "padding": "30px 0px 30px 0px",
    "border": "0.5px solid white"
  },
  close_header: {
    "marginLeft": "15px"
  }
}

const genLoader = () => {
  return (
    <div style={styles.closest}>
    <div className="text-center lighttext ">
    <p>Searching for the closest representatives</p>
    <Spinner animation="border" variant="secondary" />    
    </div>
    </div>
  )
}

const genClosest = (city) => {
  let helperText = city
  ? "Closest Procarseatsafety representative:" 
  : "Could not find a  Procarseatsafety representative nearby.";  
  return (
    <div className="lighttext" style={styles.closest} >
    <p style={styles.close_header}><strong>{helperText}</strong> {city}</p>
    </div>
  )
}

class Location extends Component {
  constructor(props) {
      super(props);
      this.state = {
//        isLoading: false,
//        userdata: null,
//        closeState: null,
//        closeLoc: null,
      }
      this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect (suggest) {
    console.dir(suggest)
    let tgtPath = ['userData', 'location'];
    let locObj = Object.assign({}, {Label: suggest.label, Lat: suggest.location.lat.toString(), Lon: suggest.location.lng.toString() });
    Actions.updateLocation({Path: tgtPath, Value: locObj});
  }  

  render() {
  let statusTile = null;
  const isLoading = this.props.loading? this.props.loading : false;
  const loc = this.props.userData? this.props.userData.location.Label : "Los Angeles, CA";
  const closeState = this.props.userData? this.props.userData.closest_city : null;
  const closeLoc = closeState? closeState.state === "notSet"? false : closeState.name : false;   
  


  if (isLoading) {
    statusTile = genLoader();
  } else if(closeLoc) {
    statusTile = genClosest(closeLoc);
  }

  return (
      <div>
      <h2 className="lighttext">Location</h2>
      <h4 className="lighttext">Select the city where the appointment should take place.</h4>

      <form>

          <Geosuggest
          placeholder="Input Location (e.g. 123 Main Street)"
          initialValue={loc}
          country="us"
          suggestItemClassName="list-group-item"
          inputClassName="form-control"
          onSuggestSelect={this.handleSelect}
          location={new window.google.maps.LatLng(53.558572, 9.9278215)}
          radius="20" />
          
          {statusTile}
          
          <Button variant="primary" 
          style={styles.btnGap} 
          className="float-right" 
          disabled={!loc}
          onClick={Actions.navFwd} >Next</Button>       

      </form>

      </div>
    )
  }
}

export default Location;