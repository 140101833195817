import React, { Component } from 'react';
import { Button, Form, Row, Col, Spinner} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Actions from '../data/Actions';

const styles = {
  medField: {
    "width": "150px"
  },
  picker: {
    "width": "150px",
  "margin": "25px 0px 25px 0px"
  },
  btnGap: {
  "margin": "25px 0px 25px 0px"
  },
  outline: {
      "borderBottom": "solid 1px #8faadc",
      "padding": "20px",
      "paddingLeft": "20px",
      "margin": "10px"
  }
}

const getAvailThisDay = (day, availArr, labels) => {
    let wkd = new Date(day).getUTCDay();
    let dayStr = labels[wkd];
    return availArr.filter( item => {
        return item.substring( 0, 2 ) === dayStr; 
    }).map( day => {
        return parseInt( day.substring( 2 ), 10 );
    });
}

const genSpinner = () => <Spinner animation="border" variant="secondary" />

const genOptions = ( label, index, handler ) => {
//    let clStat = inselection ? "timeslotSelected" : "timeslotAvail";
    return (
        <Form.Check type="radio" name="timeslot" 
        className="lighttext" id={index} key={index} label={label} onChange={handler} style={styles.outline}
        />
    )
}


class SessionSchedule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDay: null,
            selectedSlot: null,
            initialSelect: true,
            submitted: false
        }

        this.handlePicker = this.handlePicker.bind(this);
        this.handleSelectSlot = this.handleSelectSlot.bind(this);
        this.isSelectionComplete = this.isSelectionComplete.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    isSelectionComplete () {
        const sessionScheduled = this.props.userData? this.props.userData.sessionScheduled : true;
        if (this.state.submitted) {
            return false;
        } else if (!sessionScheduled) {
            return this.state.selectedDay && this.state.selectedSlot;
        } else {
            return true;
        } 
    };

    componentDidMount () {
        let today = new Date();
        let soonest = today.setDate(today.getDate()+2);        
        this.setState({selectedDay: soonest});
    }

    handleSelectSlot (e) {
        this.setState({selectedSlot: e.target.id})
    }

    handlePicker (value) {
        this.setState({selectedDay: value, initialSelect: false})
        Actions.selectDay(value)
    }

    handleSubmit () {
        this.setState({submitted: true});
        Actions.scheduleSession({date: this.state.selectedDay, slot: this.state.selectedSlot});
    }


    render() {

        const availArr = this.props.availArr? this.props.availArr : [];
        const dayLabels = this.props.dayLabels? this.props.dayLabels : null;
        const slotLabels = this.props.slotLabels? this.props.slotLabels : null;
        const alreadyScheduled = this.props.userData.sessionScheduled? this.props.userData.sessionScheduled : null; 
        const spinner = alreadyScheduled || this.state.submitted ? genSpinner() : null;

        let instructional = this.state.selectedDay? "Select a time slot." : "Select a date." ;
        let availThisDay = getAvailThisDay(this.state.selectedDay, availArr, dayLabels);

        if (this.state.initialSelect) {
            instructional = "";
        } else if (availThisDay.length === 0) {
            instructional = "There are no available time slots for that day";
        }


        const genTiles = () => {
            return slotLabels.filter((_, index)  => {
                return availThisDay.indexOf(index + 1) >= 0;
            }).map(( label, index ) => {
                let idx = index + 1;
                return genOptions ( label, idx, this.handleSelectSlot );
            });
        }

        const slotTiles = () => {
            if ( availArr && availArr.length > 0 ) {
                return genTiles();
            } else {
                return null;
            }
        }

    return (
        <div>
        <h2 className="lighttext" >Schedule Session</h2>

            <p className="lighttext"><strong>Select a date</strong></p>

            <Form> 

            <div style={styles.picker}>
            <DatePicker id="datepicker" onChange={this.handlePicker} selected={new Date(this.state.selectedDay)} />
            </div>

            <p className="lighttext"><strong>{instructional}</strong></p>

            <div key="options">
            { slotTiles()}
            </div>
            </Form>
            <Row>
            <Col>
            {spinner}
            <Button variant="primary" disabled={!this.isSelectionComplete()} onClick={this.handleSubmit} className="float-right">Schedule Session</Button>
            </Col>
            </Row>



        </div>

        )
    }
}

export default SessionSchedule;