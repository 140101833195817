export default {
    Region: 'us-east-1',
    IdentityPoolId: 'us-east-1:3380fc94-ff73-429f-8c64-4ab4f2a49868',
    EndPoint: 'https://cbnnruhhr8.execute-api.us-east-1.amazonaws.com/prod/',
    Storage: "procar.cust.storage",
    ApiName: "ProCarSeatCust",
    gapi: '802913502350-ol0fedf2gnldvedhm1rr8sljljopcebb.apps.googleusercontent.com',
    face: '1023115301121873',
    paypal: 'AUVsUqRrvrPH26qTdzykRqEkH-sB8hcwEBK_byg88WPqBqYzC3_16WmsUtrymaZk3kBdUMNJ8-UmId2E',
    gmap: 'js?key=AIzaSyDn5FUG5CBdXwb94D_1SdPr_UD2vB-nWb4&libraries=places'
}