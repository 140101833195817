import React, { Component } from 'react';
import Actions from '../data/Actions';
import trashicon from "../trash.svg";
import { Form, Card, Button, ListGroupItem, ListGroup, Modal, Row, Col } from 'react-bootstrap';

const styles = {
  section: {
    "padding": "20px 0px 20px 0px"
  },
  btnGap: {
    "margin": "25px 0px 25px 0px"
  },
  remove: {
    "cursor": "pointer"
  },
  twins: {
      "color": "red",
      "paddingRight": "20px"
  }
}

const initFields = {units: "Months", age: "", weight: "", height: "", unborn: false}


class ChildInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validated: false,
            childModal: false,
            childArr:[],
            fields: initFields
        }
        this.handleToggleModal = this.handleToggleModal.bind(this);
        this.handleToggleBorn = this.handleToggleBorn.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleAddChild = this.handleAddChild.bind(this);
        this.handleDeleteChild = this.handleDeleteChild.bind(this); 
    }

    handleToggleModal () {
        this.setState({childModal: !this.state.childModal});
        this.setState({fields: initFields});
    }

    handleAddChild (e) {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        this.setState({ validated: true });
        if (form.checkValidity()) {
            let payload = this.state.fields;
            this.setState({fields: initFields});
            this.setState({childModal: false});
            this.setState({validated: false});
            Actions.addChild(payload);
        }
    }

    handleDeleteChild (e) {
        Actions.remChild(e.target.id);
    }

    handleChange ( e ) {
        let newFields = Object.assign({}, this.state.fields, {[e.target.id]: e.target.value});
        this.setState({fields: newFields});
    }

    handleToggleBorn () {
        let newFields = Object.assign({}, this.state.fields, {unborn: !this.state.fields.unborn});
        this.setState({fields: newFields});
    }

    componentDidMount () {
        let childArr = this.props.userData? this.props.userData.childInfo? this.props.userData.childInfo : null : null; 
        if (childArr.length > 0) {
          this.setState({childArr: childArr});
        }
      }    

    componentDidUpdate (prevProps) {
        if (prevProps.userData.childInfo.length !== this.props.userData.childInfo.length) {
            this.setState({childArr: this.props.userData.childInfo})
        }
    }

    render() {
    const addChildText = this.state.childArr.length > 0 ? "+ Add another Child" :  "+ Add at least one child"; 
    const HeaderCnt = "Total number of children : " + this.state.childArr.length.toString();
    
    return (
        <div>
        <h2 className="lighttext">Child / Children Information</h2>

            <Card>
                <Card.Body>
            <Card.Title>{ HeaderCnt }</Card.Title>
                <ListGroup>
                { this.state.childArr.map(( child, index ) => {
                    if (!child.unborn) {
                    return <ListGroupItem key={index} >
                    <p><strong>Child {index + 1}</strong></p>
                    <span className="float-right" key={index} > 
                    <img src={trashicon} alt="delete" id={index} style={styles.remove} onClick={this.handleDeleteChild}/>
                    </span>
                    <strong>Age:</strong> {child.age} {child.units} 
                    <strong> / Weight: </strong> {child.weight} 
                    <strong> / Height: </strong> {child.height} 
                    </ListGroupItem>
                    } else {
                    return <ListGroupItem key={index} >
                    <p><strong>Child {index + 1}</strong></p>
                    <span className="float-right" key={index} > 
                    <img src={trashicon} alt="delete" id={index} style={styles.remove} onClick={this.handleDeleteChild}/>
                    </span>
                    Unborn Child
                    </ListGroupItem>
                    }
                })}
                </ListGroup>

                <Button onClick={this.handleToggleModal}  variant="primary">{addChildText}</Button>

                </Card.Body>
            </Card>

            <Modal id="childmodal" title="add child" show={this.state.childModal} onHide={this.handleToggleModal}>
            <Form noValidate validated={this.state.validated} onSubmit={e => this.handleAddChild(e)}>
            <Modal.Header closeButton>
                <Modal.Title>Add a child.</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <div style={styles.btnGap}>
            <Form.Group controlId="childBorn"> 
                <Form.Check onChange={this.handleToggleBorn} checked={this.state.fields.unborn} label="Child is not born yet" />
                </Form.Group>
            </div>

                <Form.Group controlId="age" ><br />
                <Form.Label>Age</Form.Label> 
                <span className="form-inline">
                <Form.Control type="text" disabled={this.state.fields.unborn} onChange={this.handleChange} value={this.state.fields.age} required={!this.state.fields.unborn} />
                <Form.Control.Feedback type="invalid">Please enter the child's age.</Form.Control.Feedback>


                <Form.Control as="select"  onChange={this.handleChange} >
                    <option value="Months">Months</option>
                    <option value="Years">Years</option>
                    <option value="Days">Days</option>
                </Form.Control>
                </span>
                </Form.Group>

                

                <Form.Group controlId="weight" >
                <Form.Label>Weight</Form.Label>
                <span className="form-inline">
                <Form.Control type="text" disabled={this.state.fields.unborn} onChange={this.handleChange} 
                value={this.state.weight} required={!this.state.fields.unborn} />{'  '}Lb.
                </span>
                <Form.Control.Feedback type="invalid">Please enter the child's weight in lb..</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="height" >
                <Form.Label>Height</Form.Label>
                <span className="form-inline">
                <Form.Control type="text" disabled={this.state.fields.unborn} onChange={this.handleChange} 
                value={this.state.height} required={!this.state.fields.unborn} />{'  '}Inches
                </span>
                <Form.Control.Feedback type="invalid">Please enter the child's height in Inches.</Form.Control.Feedback>
                </Form.Group>
                </Modal.Body>
                            
            <Modal.Footer>
                <div style={styles.twins}>For twins, only add one child.</div>
                <Button variant="default" onClick={this.handleToggleModal}>Cancel</Button>
                <Button variant="primary" type="submit" className="pull-right">Add Child</Button>
            </Modal.Footer>
            </Form>            
            </Modal>

            

            <Row>
            <Col>
            <p style={styles.btnGap}>
            <Button variant="light" onClick={Actions.navBack}> &larr; Go Back</Button>
            <Button variant="primary" type="button" disabled={this.state.childArr.length < 1? true : false} onClick={Actions.navFwd}  className="float-right">Next </Button>
            </p>
            
            </Col>
            </Row>




        </div>

        )
    }
}

export default ChildInfo;