import React, { Component } from 'react';
import { Modal, Container, Row, Col, Image, Button} from 'react-bootstrap';

const styles = {
  red: {
    "color": "rgb(239, 10, 10)"
  }
}


class landingModal extends Component {
    constructor(props) {
        super(props); 
    }

    render() {
    
    return (
       <Modal id="landingModal" size="lg" title="add child" show={this.props.show} onHide={this.props.dismiss}>
            
            <Modal.Header closeButton>
                <Modal.Title>Car Seat Safety <span style={styles.red}>Live</span> - TELEMEDICINE</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col xs={6} md={3}><Image src="img/mobile-phones-tm.png" alt="Telemedicine logo" fluid/></Col>
                        <Col xs={6} md={6}>
                        <p><strong>Welcome to Car Seat Safety <span style={styles.red}>Live</span> - Telemedicine.</strong></p>
                        <p>If you need immediate assistance with your car seat, we have Telemedicine sessions available. 
                            A Certified Child Passenger Safety Technician (CPST) who is also a Master Certified Health Education 
                            Specialist (MCHES) will provide a patient-direct, remote health education approach, 
                            to assist you with car seat. We specialize with assisting expectant couples, and new parents 
                            with their newborn baby at discharge, but can assist anyone in need. </p></Col>
                        <Col xs={6} md={3}></Col>
                    </Row>
                    <Row>
                        <Col xs={6} md={3}><Image src="img/round_logo.png" alt="Procarseatsafety logo" fluid/></Col>
                        <Col xs={6} md={6}><p>
                        We began this car seat Telemedicine approach in 2018,  to accommodate new parents who delivered 
                        their baby sooner than expected and needed immediate car seat help at discharge from the hospital 
                        or birthing center. We performed a small clinical trial and found that this method was effective, 
                        as part of the Occupant Protection section of the 2014-2019 California Strategic Highway Safety Plan 
                        (SHSP). We use a similar method of cognitive-based skills training for each session, as if we are 
                        with you in-person.</p>
                        <p><strong>Please call <span style={styles.red}>323-491-6197</span> for immediate assistance. We can assist you anywhere in the United States.
                        </strong></p>
                            </Col>
                        <Col xs={6} md={3}></Col>
                    </Row>
                    <Row>
                        <Col xs={6} md={3}><Image src="img/cpst-logo_orig.png" alt="National Child Passenger Safety Certification" fluid/></Col>
                        <Col xs={6} md={6}>
                        <p>This Telemedicine car seat session is performed by a National Highway Traffic Safety Administration 
                            (NHTSA), Certified Child Passenger Safety Technician and a Master Certified Health Education 
                            Specialist of the National Commission for Health Education Credentialing, Inc.
                            </p></Col>
                        <Col xs={6} md={3}><Image src="img/mches-logo_1_orig.png" alt="Child Health Education Specialist logo" fluid/></Col>
                    </Row>
                </Container>

            </Modal.Body>
                            
            <Modal.Footer>
                <Button variant="default" onClick={this.props.dismiss}>Cancel</Button>
            </Modal.Footer>         
            </Modal>
        )
    }
}

export default landingModal;