
/* global gapi */
/* global FB */


//import appConfig from '../configDev';
import appConfig from '../configProd';

import Amplify, { Auth, API, Storage } from 'aws-amplify';

const fb = window.FB? window.FB.Auth : null;

const identityPoolId = process.env.REACT_APP_IDENTITYPOOLID;
const region = process.env.REACT_APP_REGION;
const apiName = process.env.REACT_APP_APINAME;
const endpoint = process.env.REACT_APP_ENDPOINT;
const bucket = process.env.REACT_APP_STORAGE; 

Amplify.configure({
    Auth: {
        identityPoolId: identityPoolId,
        region: region,
        mandatorySignIn: true,
        authenticationFlowType: 'USER_PASSWORD_AUTH'
    },
    API: {  
        endpoints: [{
            name: apiName,
            endpoint: endpoint,
            region: region
        }]
    },
    Storage: {
        bucket: bucket, 
        region: region,
        identityPoolId: identityPoolId
    } 
});

const ApiOps = {

    async getCreds () {
        return await Auth.currentCredentials();
    },

    async getSession() {
        return await Auth.currentSession();
    },

    async initCreds (creds) {

        let credsObj = Object.assign({}, {
            accessKeyId: creds.cognito.config.credentials.accessKeyId,
            secretAccessKey: creds.cognito.config.credentials.secretAccessKey,
            sessionToken: creds.cognito.config.credentials.sessionToken})
        return credsObj;   
    },   

    logOut ( provObj ) { 
        return new Promise((resolve, reject) => {
            Auth.signOut({ global: true })
            .then(data => {
                if (window.gapi) {
                    let auth2 = window.gapi.auth2.getAuthInstance();
                    auth2.signOut().then(() => {
                    resolve( 'User signed out.' );
                    })
                } else if (window.FB) {
                    window.FB.logout( resp => {
                        window.FB.setAuthResponse(null, 'unknown');
                        resolve( 'User signed out.' );
                    });
                }
            })
            .catch(err => reject(err));            
        })
    },     

    async  setCredentials () {
            let creds = await Auth.currentCredentials;
            if(!creds) {
                return ('Failed to set credentials');
            }
            return ('Credentials set for Web ID');
    },  

    setIdentity ( loginObj ) {
        return new Promise (( resolve, reject ) => {
            Auth.federatedSignIn(
                loginObj.provider,
                {token: loginObj.token,
                expires_at: loginObj.expiration
                },
                loginObj.attr)
                .then(() => {
                    resolve (appConfig.paypal);
                })
                .catch(err => {
                    reject(err);
                })
        })
    },   

    async syncUserData ( payload ) {
        return Storage.put('userdata.txt', JSON.stringify(payload), {
            level: 'private',
            contentType: 'text/plain'
        });
    },

    async getSyncedData () {
        return Storage.get('userdata.txt', {
            level: 'private',
            contentType: 'text/plain'
        });
    },  

    apiFetch ( tmethod, path, payload ) {
        let params = {
            body: payload            
        }
        return API.post(appConfig.ApiName, path, params);
    }
}


export default ApiOps;