import React from 'react';
import ReactDOM from 'react-dom';
import Actions from '../data/Actions';

const {paypal} = window;
let PayPalButton = paypal.Buttons.driver('react', { React, ReactDOM });

class PaypalCheckout extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        orderId: null
      };

      this.handleCancel = this.handleCancel.bind(this);
      this.handleError = this.handleError.bind(this);
      this.handleSuccess = this.handleSuccess.bind(this);
      this.handleApprove = this.handleApprove.bind(this);
      this.handleCreateOrder = this.handleCreateOrder.bind(this);
      this.handleAuthorize = this.handleAuthorize.bind(this);

    }

    handleCreateOrder (data, actions) {
      console.log("CREATING ORDER")
      return actions.order.create({
          "intent": "CAPTURE",
          "purchase_units": [
            { "amount": { 
              "value": this.props.netcost.toString(),
              "currency_code": 'USD' 
            }
          },
        ],
      })
/*      
      .then(resp => {
        console.log('order created')
        console.log (resp)
        this.setState({orderId: resp});
      })
*/
      .catch(err => {
        console.log('failed to create order')
        console.log(err)
      })
    }

    handleSuccess (payload) {
        console.log("SUCCESS")
        console.dir(payload)
    }

    handleAuthorize (data, actions) {
      console.log("Handle Authorize")
      return actions.onAuthorize({
        "intent": "sale",
        "orderID": this.state.orderId
      })
      .catch(err => {
        console.log('failed to create order')
        console.log(err)
      })
    }

    handleApprove (data, actions) {
      return actions.order.capture()
      .then(resp => {
        console.log('captured')
        Actions.confirmPayment ( resp.id, resp.payer.payer_id, resp.purchase_units[0].amount.value )
      })
      .catch(err => {
        console.log('failed to capture')
        Actions.genStatus ( "danger", err.message);
      })
    }

    handleCancel () {
      Actions.genStatus ( "danger", "Transaction cancelled." );
    }

    handleError (err) {
      Actions.genStatus ( "danger", err.message);

    }

  
    render() {
     
        return (
          <div id="paypalContainer">
          <PayPalButton
          commit= {true}
          createOrder={ (data, actions) => this.handleCreateOrder(data, actions) }
          onApprove={ (data, actions) => this.handleApprove(data, actions) }
          onAuthorize={ (data, actions) => this.handleAuthorize(data, actions) }
          onCancel = {this.handleCancel}
          
          />

          </div>
        );
      }
  }

  export default PaypalCheckout;
  