
import React, { Component } from 'react';
import Actions from '../data/Actions';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import LandingModal from './Landing.modal.js';
import { Row, Col, Image, Container} from 'react-bootstrap';
import './landing.css';

const styles = {
    logo: {
        "margin": "0px auto 25px",
        "width": "50%"
    },
    statelogo: {
        "margin": "20px auto",
        "width": "50%"
    },
    social: {
        "marginTop": "25px",
        "textAlign": "center"
    },
    bullets: {
        "margin": '12px',
        "fontSize": '16px'
    },
    hideLogin: {
        "display": "none"
    },
    login: {
        "margin": "18px 0 18px 0"
    },
    aligntop: {
        "verticalAlign": "text-top",
        "align": "left"
    },
    headliner: {
        "margin": "5px 0px 7px 0px",
        "color": "rgb(143,170,220)",
        "textAlign": "center",
        "fontFamily": "'Quattrocento Sans', sans-serif"
    },
    padd: {
        "margin": "15px 0px 15px 0px"
    }
}

const genIcon = (label) => { 
    return (
        <div className="circle">
        <p className="label">
          {label}
        </p>
      </div>
    )
}

class Landing extends Component {
    constructor(props) {
        super(props);
        this.state = {
          showModal: false
        }
        this.handlemodal = this.handlemodal.bind(this);
    }

    handlemodal() {
        this.setState({showModal: !this.state.showModal})
    }
  
    render() {

        let socialAttr = this.props.socialAttr? Object.keys(this.props.socialAttr).length>0? true : false : false;

        const responseFacebook = ( response ) => {
            if ( response.accessToken ) {
                let usrObj = {
                    provider: 'facebook',
                    token: response.accessToken,
                    expiration: response.reauthorize_required_in, 
                    attr: {
                        email: response.email,
                        name: response.name,
                        picture: response.picture.data.url
                    }
                }
                Actions.socialLogin ( usrObj );
            } else {
                Actions.genStatus ( "danger", "There was an issue logging through Facebook. Please try again" );
            }
        }

        const responseGoogle = ( response ) => {
            if ( response.tokenObj.id_token ) {
                let usrObj = {
                    provider: "google",
                    token: response.tokenObj.id_token,
                    expiration: response.tokenObj.expires_at,
                    attr: {
                        email: response.profileObj.email,
                        name: response.profileObj.name,
                        picture: response.profileObj.imageUrl
                    }
                } 
                Actions.socialLogin( usrObj );
            } else {
                Actions.genStatus ( "danger", "There was an issue logging through Google. Please try again" );
            }
        }
    
    return (
        <Container> 
            <Row>
                <Col>
                
                </Col>
            </Row>
            <Row>
                <Col lg={6} md={5} sm={12}>
                <div>
                <p style={styles.logo} >
                    <Image src="img/round_logo.png" alt="logo" fluid/>
                </p>

                <h5 style={styles.headliner}>Keep your baby riding safe <br/>from the start</h5>

                <p style={styles.statelogo}>
                    <Image src="img/publichealthlogo.png"  fluid/>
                </p>           

                <p style={styles.statelogo}>
                    <Image src="img/car-seat-safety-live-telem-banner.png" onClick={this.handlemodal} fluid/>
                </p>
    
                <p style={styles.social}>
    
                        <a className='inline wsite-social-item wsite-social-pinterest' href='https//www.pinterest.com/proconsumersafe' target="_NEW" alt='Pinterest'>&nbsp;</a>
                        <a className='inline wsite-social-item wsite-social-rss' href='https://instagram.com/proconsumersafety' target="_NEW" alt='Rss'>&nbsp;</a>
                        <a className='inline wsite-social-item wsite-social-flickr' href='https://proconsumersafety.tumblr.com/' target="_NEW" alt='Flickr'>&nbsp;</a>
                        <a className='inline wsite-social-item wsite-social-twitter' href='https://twitter.com/ProConsumerSafe' target="_NEW" alt='Twitter'>&nbsp;</a>
                        <a className='inline wsite-social-item wsite-social-facebook' href='https//www.facebook.com/Proconsumersafety' target="_NEW" alt='Facebook'>&nbsp;</a>
                        <a className='inline wsite-social-item wsite-social-mail' href='mailto:proconsumersafety@gmail.com' target="_NEW" alt='Mail'>&nbsp;</a>
                        <a className='inline wsite-social-item wsite-social-linkedin' href='https://www.linkedin.com/company/pro-car-seat-safety' target="_NEW" alt='Linkedin'>&nbsp;</a>
                        <a className='inline last-child wsite-social-item wsite-social-youtube' href='https://www.youtube.com/channel/UCSPr4rbE0Oir8MOT_DoIRhg' target="_NEW" alt='Youtube'>&nbsp;</a>
  
                    </p>

                </div>
                </Col>
                <Col lg={6} md={7} sm={12}>
                <h1 className="lighttext">Mobile Child Safety Seat Installation/Education Service</h1>

                <h4 className="lighttext">Scheduling your seat installation is simple:</h4>

                <div className="stepstable lighttext">
                    <div className="setpsrow">
                        <div className="stepsicon">{genIcon("1")}</div>
                        <div className="stepscell">Sign-in below with Google or Facebook</div>
                    </div>
                    <div className="setpsrow">
                        <div className="stepsicon">{genIcon("2")}</div>
                        <div className="stepscell">Provide some basic information</div>
                    </div>
                    <div className="setpsrow">
                        <div className="stepsicon">{genIcon("3")}</div>
                        <div className="stepscell">Pay for the consulation</div>
                    </div>
                    <div className="setpsrow">
                        <div className="stepsicon">{genIcon("4")}</div>
                        <div className="stepscell">Schedule the installation</div>
                    </div>
                </div>                 

                    <h5 className="lighttext" ><p style={styles.padd}>A technician will meet you at a convenient location <br/> 
                    whether at your home, at work, at the hospital or at a baby store.</p></h5>
        
        <div style={!socialAttr ? styles.login : styles.hideLogin}>
            <p>            
        <GoogleLogin
        clientId={process.env.REACT_APP_GAPI}
        onSuccess={ responseGoogle }
        onFailure={ responseGoogle }
        buttonText="Login with Google"
        offline={false} />
            </p><p>
        <FacebookLogin
        appId={process.env.REACT_APP_FACE}
        autoLoad={false}
        fields="name,email,picture"
        cssClass="loginBtn loginBtn--facebook"
        style={styles.social_btn}
        callback={ responseFacebook } />
        </p>
        </div>
        <div className="lighttext">
            <h3>ABOUT US</h3>
            <p>Protecting your child is most important. As a Master of Certified Health Education Specialist, 
                an Injury Neuroepidemiologist, and a Father I have discovered the importance of applying behavior-based 
                injury prevention programs in public health. Most parents feel they are using their car seat properly, 
                but most car seats are not installed or being used correctly. As an epidemiologist, I have seen too many children 
                severely injured or killed from car seats not being installed and used properly. This is not from intentional neglect 
                of the parent or caregiver, but instead due to the complexity of instructions, and various types of car seats and models 
                of motor vehicles. To address this problem, I have developed a specialized behavioral-based car seat mobile service that 
                gives parents and caregivers the skills necessary to properly install, test and use their car seat properly, and make 
                appropriate adjustments as their child grows.  </p>

            <h3>WHY WE CHARGE A FEE</h3>
            <ul>
                <li>We come to your location (home, workplace, hospital, baby store, etc.) and take the time you need, which can take on-average 1.5 hours or more</li>
                <li>Our educational approach, part of the California Strategic Highway Safety Plan, has shown to be effective in giving you the skills necessary to secure your child properly </li>
                <li>Once you use the service you will have a Certified Technician available to answer any future question by phone</li>
                <li>We specialize with expectant parents and newborns – but are available for any age of child</li>
                <li>We use a training doll, and discuss specifics for newborn hospital discharge, as well as newborn safety tips</li>
                <li>We train all caregiver that transports the child, such as babysitter, grandparents, nanny, etc. </li>
                <li>We spend the time needed to make sure you are confident in securing who you love most - your child!</li>
            </ul>

            <h3>WHAT ABOUT NO-COST SERVICES</h3>
            <p>There are no-cost services available, usually through law enforcement and in some states fire departments (but not in California). 
                These are by appointment only and at their designated location. Many of these no-cost services have referred parents back to us, 
                because we have the ability to schedule an appointment that meet your needs.  </p>

            <h3>CUSTOMER COMMENTS</h3>
            <ul>
                <li className="quote">“Incredible wealth or knowledge and patient”</li>
                <li className="quote">“…help was invaluable…don’t hesitate to setup an appointment”</li>
                <li className="quote">“highly recommended…for anyone who is new to parenthood, or even as refresher”</li>
                <li className="quote">“thorough, helpful and professional”</li>
                <li className="quote">“very reasonably priced too”</li>
                <li className="quote">“punctual and professional”</li>
                <li className="quote">“went above and beyond to make certain we had the safest car seat for our soon to be born son”</li>
                <li className="quote">“flexible and convenient of coming to us”</li>
                <li className="quote">“a priceless service that empowers parents”</li>
            </ul>
        </div>

        <LandingModal dismiss={this.handlemodal} show={this.state.showModal} />
        </Col>
        </Row>

        </Container>
        )
    }
}

export default Landing;